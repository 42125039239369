<form #form class="card" (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
    <div class="card-body">
        <h3 class="card-body-header">{{(currentType != null ? 'changePaymentMethod' : 'addPaymentMethod') | i18n}}</h3>
        <app-payment [hideBank]="!organizationId" [hideCredit]="true"></app-payment>
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
            <span>{{'submit' | i18n}}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
            {{'cancel' | i18n}}
        </button>
    </div>
</form>
